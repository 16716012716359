import { StyledBody } from 'baseui/card';
import * as React from 'react';
import Layout from 'components/layout';
import SEO from 'components/seo';

const AboutPage = () => {
  return (
    <Layout>
      <SEO title="Home" />
      <StyledBody>
        <div>
          <p>
            Sarah takes an architectural approach to designing interiors. Fundamental spatial relationships such as counterpoints, axial symmetry, balance, proportion, and scale are constant guiding elements that contribute to establishing a peaceful order and timeless appeal.
          </p>
          <p>
            Classically trained at *Harrington College of Design* in Chicago, Sarah went on to work for the prestigious design firm *Handman Associates* before joining her husband to design and assist in managing projects for his portfolio.  Gaining an invaluable experience in construction and the project management process along the way, it has been a unique asset to her design business.
          </p>
          <p>
            In addition to residential projects in Chicago & on the North Shore, Sarah has worked on projects in Michigan and Colorado.  Sarah has also become known for her work designing commercial projects.
          </p>
          <p>
            Notable commercial projects include a wellness center & yoga studio in Northbrook, a restaurant in downtown Glencoe, and her commissioned installations for the widely acclaimed Grace restaurant in Chicago.
          </p>
        </div>
      </StyledBody>
    </Layout>
  );
};


export default AboutPage;